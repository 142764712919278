<template>
 <div class="yogo-theme">
  <div class="yogo-theme-main">
   <div class="yogo-theme-main-header">
    <h1>密室主题运营</h1>
    <!-- <div class="right">
          <div class="txt">YOGO优果真人密室逃脱(江汉路店)</div>
          <div class="icon">
            <img src="../../statics/images/头像下拉ICON 拷贝@2x.png" alt />
          </div> 
    </div>-->
    <div class="right">
     <input type="text" value="YOGO优果真人密室逃脱(江汉路店)" />
     <i class="btnicon btnimgdown"></i>
    </div>
   </div>
   <div class="yogo-theme-main-m">
    <div class="yogo-theme-main-m-t">
     <div class="yogo-theme-main-m-t-l">
      <img alt src="../../statics/images/日期翻页箭头左@2x.png" />
     </div>
     <div class="yogo-theme-main-m-t-m">
      <div>
       <span>07-14</span>
       <span>(星期日)</span>
      </div>
      <div>
       <span>07-15</span>
       <span>(星期一)</span>
      </div>
      <div>
       <span>07-16</span>
       <span>(星期二)</span>
      </div>
      <div class="active">
       <span>07-17</span>
       <span>(星期三)</span>
      </div>
      <div>
       <span>07-18</span>
       <span>(星期四)</span>
      </div>
      <div>
       <span>07-19</span>
       <span>(星期五)</span>
      </div>
      <div>
       <span>07-20</span>
       <span>(星期六)</span>
      </div>
     </div>
     <div class="yogo-theme-main-m-t-r">
      <img alt src="../../statics/images/日期翻页箭头右@2x.png" />
     </div>
     <div class="yogo-theme-main-m-t-r-date">
      <img alt src="../../statics/images/日期ICON.png" />
      <span>日期</span>
     </div>
    </div>
    <div class="yogo-theme-main-m-m">
     <div class="yogo-theme-main-m-m-tabbar">
      <div class="containner">
       <ul>
        <li class="list">
         <div>
          <p class="title">记忆碎片PLUS</p>
          <img src="../../statics/images/nav/1@2x.png" />
         </div>
         <div class="list-b">
          <!-- 结束 -->
          <div class="list-b-wrap">
           <p class="sale">已售 06/10</p>
           <p class="tip">不可拼团</p>
           <el-progress :percentage="15" :show-text="false"></el-progress>
           <p>
            <button @click="endFlag = false" class="btn btn1">结 束</button>
            <button @click="giftFlag = true" class="btn btn2">赠 品</button>
           </p>
          </div>
          <!-- 进行中 -->
          <div class="list-b-wrap" id="list-proceed">
           <p class="sale">已售 10/10</p>
           <p class="tip">不可拼团</p>
           <el-progress :percentage="85" :show-text="false"></el-progress>
           <p>
            <button @click="ingFlag = true" class="btn" id="success">进行中</button>
           </p>
          </div>
          <!-- 预定 -->
          <div class="list-b-wrap" id="reserve">
           <p class="sale">已售 08/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = true" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
          <div class="list-b-wrap" id="reserve">
           <p class="sale">已售 02/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = true" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
          <!-- 最后个 -->
          <div class="list-b-wrap last" id="reserve">
           <p class="sale">已售 10/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = false" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
         </div>
        </li>
        <li class="list">
         <div>
          <p class="title">记忆碎片PLUS</p>
          <img src="../../statics/images/nav/1@2x.png" />
         </div>
         <div class="list-b">
          <div class="list-b-wrap">
           <p class="sale">已售 06/10</p>
           <p class="tip">不可拼团</p>
           <el-progress :percentage="15" :show-text="false"></el-progress>
           <p>
            <button @click="endFlag = false" class="btn btn1">结 束</button>
            <button @click="giftFlag = true" class="btn btn2">赠 品</button>
           </p>
          </div>
          <div class="list-b-wrap last" id="reserve">
           <p class="sale">已售 10/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = false" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
         </div>
        </li>
        <li class="list">
         <div>
          <p class="title">记忆碎片PLUS</p>
          <img src="../../statics/images/nav/1@2x.png" />
         </div>
         <div class="list-b">
          <!-- 1 -->
          <div class="list-b-wrap">
           <p class="sale">已售 04/10</p>
           <p class="tip">不可拼团</p>
           <el-progress :percentage="15" :show-text="false"></el-progress>
           <p>
            <button @click="endFlag = false" class="btn btn1">结 束</button>
            <button @click="giftFlag = true" class="btn btn2">赠 品</button>
           </p>
          </div>
          <!-- 2 -->
          <div class="list-b-wrap" id="list-proceed">
           <p class="sale">已售 10/10</p>
           <p class="tip">不可拼团</p>
           <el-progress :percentage="85" :show-text="false"></el-progress>
           <p>
            <button @click="ingFlag = true" class="btn" id="success">进行中</button>
           </p>
          </div>
          <!-- 3 -->
          <div class="list-b-wrap last" id="reserve">
           <p class="sale">已售 10/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = false" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
          <!-- 预定 -->
          <div class="list-b-wrap" id="reserve">
           <p class="sale">已售 08/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = true" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
          <!-- 最后个 -->
          <div class="list-b-wrap last" id="reserve">
           <p class="sale">已售 10/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = false" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
         </div>
        </li>
        <li class="list">
         <div>
          <p class="title">记忆碎片PLUS</p>
          <img src="../../statics/images/nav/1@2x.png" />
         </div>
         <div class="list-b">
          <div class="list-b-wrap">
           <p class="sale">已售 06/10</p>
           <p class="tip">不可拼团</p>
           <el-progress :percentage="15" :show-text="false"></el-progress>
           <p>
            <button @click="endFlag = false" class="btn btn1">结 束</button>
            <button @click="giftFlag = true" class="btn btn2">赠 品</button>
           </p>
          </div>
          <!-- 最后个 -->
          <div class="list-b-wrap last" id="reserve">
           <p class="sale">已售 10/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = false" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
          <!-- 最后个 -->
          <div class="list-b-wrap last" id="reserve">
           <p class="sale">已售 10/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = false" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
         </div>
        </li>
        <li class="list">
         <div>
          <p class="title">记忆碎片PLUS</p>
          <img src="../../statics/images/nav/1@2x.png" />
         </div>
         <div class="list-b">
          <div class="list-b-wrap">
           <p class="sale">已售 06/10</p>
           <p class="tip">不可拼团</p>
           <el-progress :percentage="15" :show-text="false"></el-progress>
           <p>
            <button @click="endFlag = false" class="btn btn1">结 束</button>
            <button @click="giftFlag = true" class="btn btn2">赠 品</button>
           </p>
          </div>
          <!-- 进行中 -->
          <div class="list-b-wrap" id="list-proceed">
           <p class="sale">已售 10/10</p>
           <p class="tip">不可拼团</p>
           <el-progress :percentage="85" :show-text="false"></el-progress>
           <p>
            <button @click="ingFlag = true" class="btn" id="success">进行中</button>
           </p>
          </div>
          <!-- 预定 -->
          <div class="list-b-wrap" id="reserve">
           <p class="sale">已售 08/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = true" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
          <!-- 最后个 -->
          <div class="list-b-wrap last" id="reserve">
           <p class="sale">已售 10/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = false" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
          <!-- 预定 -->
          <div class="list-b-wrap" id="reserve">
           <p class="sale">已售 08/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = true" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
         </div>
        </li>
        <li class="list">
         <div>
          <p class="title">记忆碎片PLUS</p>
          <img src="../../statics/images/nav/1@2x.png" />
         </div>
         <div class="list-b">
          <!-- 进行中 -->
          <div class="list-b-wrap" id="list-proceed">
           <p class="sale">已售 10/10</p>
           <p class="tip">不可拼团</p>
           <el-progress :percentage="85" :show-text="false"></el-progress>
           <p>
            <button @click="ingFlag = true" class="btn" id="success">进行中</button>
           </p>
          </div>
          <!-- 预定 -->
          <div class="list-b-wrap" id="reserve">
           <p class="sale">已售 08/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = true" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
          <!-- 预定 -->
          <div class="list-b-wrap" id="reserve">
           <p class="sale">已售 08/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = false" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
         </div>
        </li>
        <li class="list">
         <div>
          <p class="title">记忆碎片PLUS</p>
          <img src="../../statics/images/nav/1@2x.png" />
         </div>
         <div class="list-b">
          <div class="list-b-wrap">
           <p class="sale">已售 06/10</p>
           <p class="tip">不可拼团</p>
           <el-progress :percentage="15" :show-text="false"></el-progress>
           <p>
            <button @click="endFlag = false" class="btn btn1">结 束</button>
            <button @click="giftFlag = true" class="btn btn2">赠 品</button>
           </p>
          </div>
          <!-- 预定 -->
          <div class="list-b-wrap" id="reserve">
           <p class="sale">已售 08/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = true" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
          <!-- 最后个 -->
          <div class="list-b-wrap last" id="reserve">
           <p class="sale">已售 10/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = false" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
         </div>
        </li>
        <li class="list">
         <div>
          <p class="title">记忆碎片PLUS</p>
          <img src="../../statics/images/nav/1@2x.png" />
         </div>
         <div class="list-b">
          <!-- 结束 -->
          <div class="list-b-wrap">
           <p class="sale">已售 06/10</p>
           <p class="tip">不可拼团</p>
           <el-progress :percentage="15" :show-text="false"></el-progress>
           <p>
            <button @click="endFlag = false" class="btn btn1">结 束</button>
            <button @click="giftFlag = true" class="btn btn2">赠 品</button>
           </p>
          </div>
          <!-- 进行中 -->
          <div class="list-b-wrap" id="list-proceed">
           <p class="sale">已售 10/10</p>
           <p class="tip">不可拼团</p>
           <el-progress :percentage="85" :show-text="false"></el-progress>
           <p>
            <button @click="ingFlag = true" class="btn" id="success">进行中</button>
           </p>
          </div>
          <!-- 预定 -->
          <div class="list-b-wrap" id="reserve">
           <p class="sale">已售 08/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = true" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
          <div class="list-b-wrap" id="reserve">
           <p class="sale">已售 02/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = true" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
          <!-- 最后个 -->
          <div class="list-b-wrap last" id="reserve">
           <p class="sale">已售 10/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = false" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
         </div>
        </li>
        <li class="list">
         <div>
          <p class="title">记忆碎片PLUS</p>
          <img src="../../statics/images/nav/1@2x.png" />
         </div>
         <div class="list-b">
          <!-- 结束 -->
          <div class="list-b-wrap">
           <p class="sale">已售 06/10</p>
           <p class="tip">不可拼团</p>
           <el-progress :percentage="15" :show-text="false"></el-progress>
           <p>
            <button @click="endFlag = false" class="btn btn1">结 束</button>
            <button @click="giftFlag = true" class="btn btn2">赠 品</button>
           </p>
          </div>
          <!-- 进行中 -->
          <div class="list-b-wrap" id="list-proceed">
           <p class="sale">已售 10/10</p>
           <p class="tip">不可拼团</p>
           <el-progress :percentage="85" :show-text="false"></el-progress>
           <p>
            <button class="btn" id="success">进行中</button>
           </p>
          </div>
          <!-- 预定 -->
          <div class="list-b-wrap" id="reserve">
           <p class="sale">已售 08/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = true" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
          <div class="list-b-wrap" id="reserve">
           <p class="sale">已售 02/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = true" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
          <!-- 最后个 -->
          <div class="list-b-wrap last" id="reserve">
           <p class="sale">已售 10/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = false" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
         </div>
        </li>
        <li class="list">
         <div>
          <p class="title">记忆碎片PLUS</p>
          <img src="../../statics/images/nav/1@2x.png" />
         </div>
         <div class="list-b">
          <div class="list-b-wrap">
           <p class="sale">已售 06/10</p>
           <p class="tip">不可拼团</p>
           <el-progress :percentage="15" :show-text="false"></el-progress>
           <p>
            <button @click="endFlag = false" class="btn btn1">结 束</button>
            <button @click="giftFlag = true" class="btn btn2">赠 品</button>
           </p>
          </div>
          <!-- 最后个 -->
          <div class="list-b-wrap last" id="reserve">
           <p class="sale">已售 10/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = false" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
          <!-- 最后个 -->
          <div class="list-b-wrap last" id="reserve">
           <p class="sale">已售 10/10</p>
           <p class="tip">可拼团</p>
           <el-progress :percentage="0" :show-text="false"></el-progress>
           <p>
            <button @click="subscribeFlag = false" class="btn btn1">预 定</button>
            <button @click="startFlag = true" class="btn btn2">开 始</button>
           </p>
          </div>
         </div>
        </li>
       </ul>
      </div>
     </div>
     <div class="times-yAxis">
      <div class="yAxis-left">
       <p>08:00</p>
       <p>08:30</p>
       <p>09:00</p>
       <p>09:30</p>
       <p>10:00</p>
       <p>10:30</p>
       <p>11:00</p>
       <p>11:30</p>
       <p>12:00</p>
       <p>12:30</p>
       <p>13:00</p>
       <p>13:30</p>
       <p>14:00</p>
       <p>14:30</p>
       <p>15:00</p>
       <p>15:30</p>
       <p>16:00</p>
       <p>16:30</p>
       <p>17:00</p>
       <p>17:30</p>
       <p>18:00</p>
       <p>18:30</p>
       <p>19:00</p>
       <p>19:30</p>
       <p>20:00</p>
       <p>20:30</p>
       <p>21:00</p>
       <p>21:30</p>
       <p>22:00</p>
       <p>22:30</p>
       <p>23:00</p>
       <p>23:30</p>
      </div>
      <!-- 时间轴 -->
      <div class="time-banner">
       <!-- <div v-for="(item,index) in 40" :key='index'></div> -->
      </div>
     </div>
    </div>

    <!-- 开始弹窗 -->
    <el-dialog :close-on-click-modal="false" :visible.sync="startFlag" center class="contentList start" title="开启密室主题">
     <div class="startImg">
      <img alt class="startPic" src="../../statics/images/主题拷贝@2x.png" />
     </div>
     <div class="isOrNotstart">是否开启当前密室主题</div>
     <span class="dialog-footer" slot="footer">
      <el-button @click="startFlag = false" class="confime" type="primary">确 定</el-button>
      <el-button @click="startFlag = false">取 消</el-button>
     </span>
    </el-dialog>

    <!-- 结束弹窗 -->
    <el-dialog :close-on-click-modal="false" :visible.sync="endFlag" center class="contentList start" title="是否通关">
     <div class="startImg">
      <img alt class="startPic" src="../../statics/images/主题拷贝@2x.png" />
     </div>
     <div class="isOrNotstart">当前密室是否通关</div>
     <span class="dialog-footer" slot="footer">
      <el-button @click="endFlag = false" class="confime" type="primary">成 功</el-button>
      <el-button @click="endFlag = false" class="fail">惜 败</el-button>
     </span>
    </el-dialog>

    <!-- 进行中弹窗 -->
    <el-dialog :close-on-click-modal="false" :visible.sync="ingFlag" center class="contentList doIng" title="结束密室主题">
     <div class="doIngAround">
      <el-progress :percentage="50" :show-text="false" type="circle"></el-progress>
      <div class="outAround">
       <div class="innerAround">
        <p>剩余时间</p>
        <p>00:12:11</p>
        <p>记忆的碎片PLUS</p>
        <p>当前耗时</p>
        <p>00:57:21</p>
       </div>
      </div>
     </div>

     <span class="dialog-footer" slot="footer">
      <el-button @click="overIngFlag = true" class="confime" type="primary">
       <img alt src="../../statics/images/结束@2x.png" />
       <span>结 束</span>
      </el-button>
     </span>
    </el-dialog>

    <!-- 进行中结束弹窗 -->
    <el-dialog :close-on-click-modal="false" :visible.sync="overIngFlag" center class="contentList start" title="结束密室主题">
     <div class="startImg">
      <img alt class="startPic" src="../../statics/images/主题拷贝@2x.png" />
     </div>
     <div class="isOrNotstart">是否结束当前密室主题</div>
     <span class="dialog-footer" slot="footer">
      <el-button @click="overIngFlag = false,ingFlag=false" class="confime" type="primary">确 定</el-button>
      <el-button @click="overIngFlag = false">取 消</el-button>
     </span>
    </el-dialog>

    <!-- 赠品弹窗 -->
    <el-dialog :close-on-click-modal="false" :visible.sync="giftFlag" center class="giftContentList gift" title="游戏赠品">
     <div class="giftImg">
      <img alt class="giftPic" src="../../statics/images/主题拷贝@2x.png" />
     </div>
     <div class="isOrNotgift">密室通关成功</div>
     <div class="giftContent">
      <ul :key="item.id" v-for="(item, index) in giftArr">
       <li class="giftName">
        <p>{{item.giftName}}{{index+1}}</p>
        <form action="#" method="get">
         <input placeholder="请输入赠品名称" type="text" />
        </form>
       </li>
       <li class="giftNum">
        <p>{{item.giftNum}}</p>
        <form action="#" method="get">
         <input placeholder="请输入赠品数量" type="text" />
        </form>
       </li>
       <li @click="reduceArr(index)" class="delGift">
        <p>
         <img alt src="../../statics/images/删除@2x.png" />
        </p>
       </li>
      </ul>
     </div>
     <div class="addGift">
      <p @click="addArr">+添加赠品</p>
     </div>
     <div class="helpMan">
      <p>引导人员</p>
      <form action="#">
       <input placeholder="请选择" type="text" />
      </form>
      <p class="downIcon">
       <img alt src="../../statics/images/头像下拉ICON 拷贝 6@2x.png" />
      </p>
     </div>
     <span class="dialog-footer" slot="footer">
      <el-button @click="giftFlag = false" class="confime" type="primary">确 定</el-button>
      <el-button @click="giftFlag = false">取 消</el-button>
     </span>
    </el-dialog>

    <!-- 预定弹窗 -->
    <el-dialog :close-on-click-modal="false" :visible.sync="subscribeFlag" center class="subscribeContentList subscribe" title="预定信息">
     <!-- 客户信息 -->
     <div class="customInfo">
      <div class="customTitle">
       <p>
        <img alt src="../../statics/images/矩形 1 拷贝 9@2x.png" />客户信息
       </p>
       <p @click="changelan" v-if="openOrclose">
        <img alt src="../../statics/images/导航栏下拉icon-up.png" />
       </p>
       <p @click="changelan" v-else>
        <img alt src="../../statics/images/icon-d.png" />
       </p>
      </div>
      <div class="contentInfo" v-show="isShow">
       <ul :key="item.itemId" v-for="(item, index) in subscribeArr">
        <li>
         <div class="customName">
          <p>{{item.customName}}</p>
          <form action="#" method="get">
           <input :class="{activeLine:isActiveLine}" placeholder="请输入客户姓名" type="text" />
          </form>
         </div>
         <div class="customPhone">
          <p>{{item.customPhone}}</p>
          <form action="#" method="get">
           <input placeholder="请输入客户电话" type="text" />
          </form>
         </div>
         <div @click="delArr(index)" class="delCustom">
          <p>
           <img alt src="../../statics/images/删除@2x.png" />
          </p>
         </div>
        </li>
        <li>
         <div class="guessAge">
          <p>{{item.guessAge}}</p>
          <form action="#" method="get">
           <input placeholder="11~14岁" type="text" />
          </form>
          <p class="downIcon">
           <img alt src="../../statics/images/头像下拉ICON 拷贝 6@2x.png" />
          </p>
         </div>
         <div class="orderNum">
          <p>{{item.orderNum}}</p>
          <form action="#" method="get">
           <input placeholder="请输入订单人数" type="text" />
          </form>
         </div>
         <div class="customNum">
          <p>{{index+1}}</p>
         </div>
        </li>
       </ul>
       <div class="addCustom">
        <p @click="addCustomArr">+添加客户</p>
       </div>
      </div>
     </div>
     <!-- 订单信息 -->
     <div class="orderForm">
      <div class="orderFormTitle">
       <p>
        <img alt src="../../statics/images/矩形 1 拷贝 9@2x.png" />订单信息
       </p>
       <p @click="changelan1" v-if="openOrclose1">
        <img alt src="../../statics/images/导航栏下拉icon-up.png" />
       </p>
       <p @click="changelan1" v-else>
        <img alt src="../../statics/images/icon-d.png" />
       </p>
      </div>
      <div class="orderFormContent" v-show="isShow1">
       <ul>
        <li>
         <div class="orderFormtotal">
          <p>订单总数</p>
          <form action="#" method="get">
           <input placeholder="请输入订单总数" type="text" />
          </form>
         </div>
        </li>
        <li>
         <div class="isOrNotTeam">
          <p>是否组队</p>
          <form action="#" method="get">
           <input placeholder="请选择" type="text" />
          </form>
          <p class="downIcon">
           <img alt src="../../statics/images/头像下拉ICON 拷贝 6@2x.png" />
          </p>
         </div>
        </li>
       </ul>
       <div class="payWay">
        <div>
         <p>付款方式</p>
         <div :key="index" class="payWayList" v-for="(item, index) in payWayData">
          <p @click="select(index)" v-if="item.isSelected">
           <img :src="item.pic1" alt />
          </p>
          <p @click="select(index)" v-else>
           <img :src="item.pic2" alt />
          </p>
          <p>{{item.way}}</p>
         </div>
        </div>
        <div>
         <p>付款金额</p>
         <form :key="index" action="#" v-for="(item, index) in readOnly">
          <input :disabled="item" placeholder="请输入支付金额" type="text" />
         </form>
        </div>
       </div>
      </div>
     </div>
     <!-- 付款金额 -->
     <div class="payMoney">
      <div class="payMoneyTitle">
       <p>
        <img alt src="../../statics/images/矩形 1 拷贝 9@2x.png" />支付金额
       </p>
       <p @click="changelan2" v-if="openOrclose2">
        <img alt src="../../statics/images/导航栏下拉icon-up.png" />
       </p>
       <p @click="changelan2" v-else>
        <img alt src="../../statics/images/icon-d.png" />
       </p>
      </div>

      <!-- 付款列表 -->
      <div class="payMoneyList" v-show="isShow2">
       <ul>
        <li>
         <p>支付宝[扫码]</p>
         <p>---------------------------------------------------------------------------</p>
         <p>
          139元/人
          <span>&nbsp;&nbsp;x2</span>
         </p>
        </li>
        <li>
         <p>支付宝[验券]</p>
         <p>---------------------------------------------------------------------------</p>
         <p>
          139元/人
          <span>&nbsp;&nbsp;x2</span>
         </p>
        </li>
        <li>
         <p>优果君微信</p>
         <p>-----------------------------------------------------------------------------</p>
         <p>
          139元/人
          <span>&nbsp;&nbsp;x2</span>
         </p>
        </li>
        <li>
         <p>订单总数</p>
         <p>----------------------------------------------------------------------------</p>
         <p class="total">
          <span>￥</span>
          <span>834.00</span>
         </p>
        </li>
       </ul>
      </div>
     </div>
     <span class="dialog-footer" slot="footer">
      <el-button @click="subscribeFlag = false" class="confime" type="primary">确 定</el-button>
      <el-button @click="subscribeFlag = false">取 消</el-button>
     </span>
    </el-dialog>
   </div>
  </div>
 </div>
</template>
<script>
export default {
 name: 'theme',
 data() {
  return {
   activeName: 'second',
   // 开始弹窗
   startFlag: false,
   // 结束弹窗
   endFlag: false,
   // 进行中弹窗
   ingFlag: false,
   // 进行中结束弹窗
   overIngFlag: false,
   // 赠品弹窗
   giftFlag: false,
   giftArr: [{ id: 0, giftName: '赠品', giftNum: '赠品数量' }],
   id: 0,
   // 预定弹窗
   isActiveLine: true,
   subscribeFlag: false,
   subscribeArr: [
    {
     itemId: 0,
     customName: '客户姓名',
     customPhone: '客户电话',
     guessAge: '预估年龄',
     orderNum: '订单人数',
    },
   ],
   itemId: 0,
   // 开启收起
   openOrclose: true,
   openOrclose1: true,
   openOrclose2: true,
   // 支付方式
   payWayData: [
    {
     pic1: require('../../statics/images/select13@2x.png'),
     pic2: require('../../statics/images/勾选框 拷贝 13@2x.png'),
     way: '支付宝[扫码]',
     isSelected: true,
    },
    {
     pic1: require('../../statics/images/select13@2x.png'),
     pic2: require('../../statics/images/勾选框 拷贝 13@2x.png'),
     way: '支付宝[验券]',
     isSelected: true,
    },
    {
     pic1: require('../../statics/images/select13@2x.png'),
     pic2: require('../../statics/images/勾选框 拷贝 13@2x.png'),
     way: '支付宝[验券]',
     isSelected: true,
    },
    {
     pic1: require('../../statics/images/select13@2x.png'),
     pic2: require('../../statics/images/勾选框 拷贝 13@2x.png'),
     way: '大众/美团[验券]',
     isSelected: false,
    },
    {
     pic1: require('../../statics/images/select13@2x.png'),
     pic2: require('../../statics/images/勾选框 拷贝 13@2x.png'),
     way: '大众/美团[订座]',
     isSelected: false,
    },
    {
     pic1: require('../../statics/images/select13@2x.png'),
     pic2: require('../../statics/images/勾选框 拷贝 13@2x.png'),
     way: '大众/美团[闪惠]',
     isSelected: false,
    },
    {
     pic1: require('../../statics/images/select13@2x.png'),
     pic2: require('../../statics/images/勾选框 拷贝 13@2x.png'),
     way: '会员储值金消费',
     isSelected: false,
    },
    {
     pic1: require('../../statics/images/select13@2x.png'),
     pic2: require('../../statics/images/勾选框 拷贝 13@2x.png'),
     way: '刷卡',
     isSelected: false,
    },
    {
     pic1: require('../../statics/images/select13@2x.png'),
     pic2: require('../../statics/images/勾选框 拷贝 13@2x.png'),
     way: '现金',
     isSelected: false,
    },
   ],
   // 是否只读
   readOnly: [false, false, false, true, true, true, true, true, true],
   isShow: false,
   isShow1: false,
   isShow2: true,
  }
 },
 methods: {
  handleClick(tab, event) {
   //  ;
  },
  // 增加赠品
  addArr() {
   this.id++
   this.giftArr.push({ id: this.id, giftName: '赠品', giftNum: '赠品数量' })
  },
  // 删除赠品
  reduceArr(index) {
   //  ;
   this.giftArr.splice(index, 1)
  },
  // 增加客户
  addCustomArr() {
   this.itemId++
   this.subscribeArr.push({
    itemId: this.itemId,
    customName: '客户姓名',
    customPhone: '客户电话',
    guessAge: '预估年龄',
    orderNum: '订单人数',
   })
  },
  // 删除客户
  delArr(index) {
   //  ;
   this.subscribeArr.splice(index, 1)
  },
  changelan() {
   // this.openOrclose = false;
   this.openOrclose = !this.openOrclose
   this.isShow = !this.isShow
  },
  changelan1() {
   // this.openOrclose = false;
   this.openOrclose1 = !this.openOrclose1
   this.isShow1 = !this.isShow1
  },
  changelan2() {
   // this.openOrclose = false;
   this.openOrclose2 = !this.openOrclose2
   this.isShow2 = !this.isShow2
  },
  select(index) {
   this.payWayData[index].isSelected = !this.payWayData[index].isSelected
   this.readOnly[index] = !this.readOnly[index]
  },
 },
}
</script>
<style>
body {
 padding: 0 !important;
}
</style>
<style scoped>
@import '../../statics/css/theme.css';
</style>
